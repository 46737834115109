@import "@/common/scss/variables.scss";

.headerProjectDropdown {
  width: 100%;
  border: none !important;
  color: #555555 !important;
  position: relative;
  padding: 0 !important;
  margin: 0 !important;
}

.headerProjectDropdownWrapper {
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 120px;
  width: 348px !important;
  margin-left: -5px !important;
  top: 15px !important;
}

.errorPublish {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 58px;
  background-color: $red-color;
  width: 100vw;
  display: flex;
  align-items: center;
  color: #fff;
  padding-left: 20px;
  font-weight: bold;
}

.errorIcon {
  position: absolute;
  right: 20px;
  top: 50%;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  margin-top: -10px;

  &:hover {
    opacity: 0.6;
  }
}

.headerProjectHeader {
  position: relative;
  display: flex;
}

.headerProjectUncontroller {
  padding-top: 5px;
}

.headerProjectTitle.headerProjectTitle {
  transition: opacity 0.3s ease-out;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-left: 20px;

  &:hover {
    opacity: 0.7;
  }
}

.headerProjectTitleIcon {
  height: 28px;
  width: 38px;
}

.headerPublishWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
}

.headerPublishDate {
  font-size: 12px;
  line-height: 17px;
  display: flex;
  flex-direction: column;
  text-align: left;
  color: #999;
  max-width: 114px;
  margin-left: 12px;
}

.headerShare.headerShare.headerShare {
  padding: 0;
  color: $secondary-color;
  border-color: $secondary-color;
  width: 30px;
  height: 30px;

  svg {
    fill: $secondary-color;
    margin: 0;
  }
}

.headerProject {
  display: flex;
  align-items: center;
}

.createProjectModal.createProjectModal {
  padding: 0;
  border-radius: 5px;
}

.createProjectModalHost.createProjectModalHost {
  max-width: initial;
}

.createProjectModalBackdrop.createProjectModalBackdrop.createProjectModalBackdrop {
  background-color: rgba(57, 61, 65, 0.93) !important;
}

.headerShareDropdownMenu {

  & :global(.dropdown-menu) {
    transform: translate3d(34px, 25px, 0px) !important;
  }
}

.headerShareTitle {
  font-size: 15px;
  margin-bottom: 12px;
}

.headerShareSubtitle {
  font-size: 14px;
  margin: 12px 0;
}

.headerShareDivider {
  position: absolute;
  left: 0;
  width: 100%;
  border: 1px solid #DDDDDD;
}

.headerShareInput {
  width: 100%;
  font-size: 14px;
}

.headerShareIcon {
  cursor: pointer;
}

.headerPublishBtn.headerPublishBtn.headerPublishBtn {
  padding-left: 32px !important;
  padding-right: 32px !important;
  height: 30px;
  margin-right: 10px;
}

.headerAddProjectButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.errorPublishText {
  color: $red-color;
  text-decoration: underline;
}

.headerProjectOrgName {
  font-weight: 500 !important;
}

.headerProjectProjectsWrapper {
  display: block;
  max-height: 385px;
  overflow: auto;
}
