html {
    font-family: 'Roboto', sans-serif;
}

button {
    font-family: inherit;
}

.btn {
    margin: 0;
}

label {
    margin: 0;
}

a {
    text-decoration: none;
}

.ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >td {
    background: #fff;
}


.ant-table-cell {
    /*background: #e6f4ff;*/
}

.ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected:hover>td {
    background: rgba(245, 242, 242);
}

.ant-table-wrapper .ant-table-cell-fix-left {
    /*background: #e6f4ff;*/
}
