@import "@/common/scss/variables.scss";

.editor {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;

  padding-top: 30px;
  padding-bottom: 20px;

  overflow-y: auto;
}

.flexCenter {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.ruleWrapper {
  padding: 9px 17px 10px 17px;
  margin-bottom: 14px;
  background-color: #F5F5F5;
  border-radius: 5px;
}

.ruleTitle {
  font-size: 14px;
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.ruleClose {
  cursor: pointer;
}

.group {
  color: $primary-text-color;
  font-size: 13px;
  margin-bottom: 16px;
  padding: 0  15px;

  &:first-child {
    flex: none;
  }
}

.button {
  background: #FFFFFF;
  border: 1px solid #EBEDEF;
  box-sizing: border-box;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.0712466);
  border-radius: 5px;
  padding: 16px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    .remove {
      opacity: 1;
    }
  }


  .remove {
    opacity: 0;
  }
}

.groupTitle {
  color: $primary-text-color !important;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: bold;
}

.input {
  display: block;
  width: 100%;
  height: 32px;
  border-radius: 5px;
  border: 1px solid $input-border-color;
  box-sizing: border-box;
  font-size: 14px;
  color: $primary-text-color;
  background-color: white;
  appearance: none;
  padding: 0 16px 0 10px;

  &:invalid {
    color: $gray-color;
  }

  &[disabled] {
    opacity: .6;
    color: $gray-color;
    border-color: $gray-color;
  }
}

textarea.input {
  padding: 8px;
  min-height: 64px;
}



.label {
  position: relative;
  width: 100%;
  margin-bottom: 8px;
  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 9px;
    right: 8px;
    width: 10px;
    height: 10px;
    border-bottom: 3px solid #979797;
    border-right: 3px solid #979797;
    transform: rotateZ(45deg);
    pointer-events: none;
  }

  &:last-child {
    margin: 0;
  }
}

.or {
  content: '';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4px auto 12px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: $border-color;
  color: $gray-color;
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;
}

.or:last-child {
  display: none;
}

.orActive {
  background-color: $blue-color;
  color: white;
}

.entitySelector {
  color: #999;
}

.addButton {
  text-align: center;
  margin: 0 auto;
  padding: 10px 18px;
  background: #116FFC;
  border-radius: 5px;
  color: white;
  border: none;
  &:hover {
    opacity: .8;
  }
}

.removeRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  .remove {
    margin-left: auto;
    cursor: pointer;
  }

  .label {
    margin: 0;
  }
}

.inputRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .input:not(:last-child) {
    margin-right: 8px;
  }

  margin-bottom: 8px;
}

.currentLocationButton {
  width: 32px;
  height: 32px;
  display: block;
  border-radius: 5px;
  background-color: #F2F4F7;
  border: none;
  padding: 4px;
}

.removeIcon {
  pointer-events: none;
}

.delete{
  display: inherit;
  cursor: pointer;
}

.groupRow{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
