@import "@/common/scss/variables.scss";

.chat {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;

  & > div {
    display: flex;
    flex-direction: column;
  }
}

.date {
  font-size: 14px;
  color: $gray-color;
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}



.logsContainer {
  padding: 25px 45px;
  display: flex;
  flex-direction: column;
}
